<template>
    <el-dialog :title="title" :close-on-click-modal="false" :fullscreen="true" append-to-body :visible.sync="visible"
               class="collectForm dialogTable">
        <div v-loading="loading" class="formBox">
            <el-form size="small" :inline="true" :model="inputForm" :rules="dataRule" ref="inputForm"
                     label-width="180px" :disabled="method==='view'" class="recordCollect">
                <el-form-item label="借阅单号：" prop="borrowCodeNumber" v-if="method != 'add'">
                    <el-input v-model.trim="inputForm.borrowCodeNumber" disabled></el-input>
                </el-form-item>
                <el-form-item label="借阅人：" prop="borrowUserIdName">
                    <el-input v-model.trim="inputForm.borrowUserIdName" maxlength="20"
                              placeholder="请输入借阅人姓名。（限20字）" disabled></el-input>
                </el-form-item>
                <el-form-item label="手机：" prop="telPhone">
                    <el-input v-model.trim="inputForm.telPhone" maxlength="11" placeholder="请输入手机号" disabled></el-input>
                </el-form-item>
                <el-form-item label="借阅人机构：" prop="unit">
                    <el-input v-model.trim="inputForm.unit" maxlength="30" placeholder="请输入借阅人机构。（限30字）" disabled></el-input>
                </el-form-item>
                <el-form-item label="借阅时间：" prop="borrowTime">
                    <el-date-picker class="w100i"
                                    v-model="inputForm.borrowTime" value-format="yyyy-MM-dd"
                                    format="yyyy-MM-dd"
                                    type="date" clearable placeholder="请选择">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="规划期限：" prop="deadline">
                    <el-date-picker class="w100i"
                                    v-model="inputForm.deadline" value-format="yyyy-MM-dd"
                                    format="yyyy-MM-dd"
                                    type="date" clearable placeholder="请选择">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="借阅目的：" prop="borrowPurpose">
                    <el-select class="w100i" v-model="inputForm.borrowPurpose"
                               clearable placeholder="请选择">
                        <el-option
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                                v-for="item in $dictUtils.getDictList('borrow_purpose')">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="借阅经办人：" prop="lendUserName" v-if="method == 'view'">
                    <el-input v-model.trim="inputForm.lendUserName" disabled></el-input>
                </el-form-item>
                <el-form-item label="经办时间：" prop="lendTime" v-if="method == 'view'">
                    <el-input v-model.trim="inputForm.lendTime" disabled></el-input>
                </el-form-item>
                <el-form-item label="归还状态：" prop="returnState" v-if="method == 'view'">
                    <el-select class="w100i" v-model="inputForm.returnState" disabled>
                        <el-option
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                                v-for="item in $dictUtils.getDictList('return_state')">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="归还核验人：" prop="returnVerificationPersonName" v-if="method == 'view'">
                    <el-input v-model.trim="inputForm.returnVerificationPersonName"></el-input>
                </el-form-item>
                <el-form-item label="归还时间：" prop="returnTime" v-if="method == 'view'">
                    <el-input v-model.trim="inputForm.returnTime" disabled></el-input>
                </el-form-item>
                <el-form-item label="审核状态：" prop="auditState" v-if="method != 'add'">
                    <el-input disabled v-if="inputForm.auditState == '0'"></el-input>
                    <el-select class="w100i" v-model="inputForm.auditState" disabled v-else>
                        <el-option
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                                v-for="item in $dictUtils.getDictList('audit_state')">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="审核人：" prop="auditUserIdName" v-if="method != 'add'">
                    <el-input v-model.trim="inputForm.auditUserIdName" disabled></el-input>
                </el-form-item>
                <el-form-item label="审核时间：" prop="auditTime" v-if="method != 'add'">
                    <el-input v-model.trim="inputForm.auditTime" disabled></el-input>
                </el-form-item>
                <el-form-item label="反馈详情：" prop="feedback" v-if="method != 'add'">
                    <el-input v-model.trim="inputForm.feedback" disabled></el-input>
                </el-form-item>
                <el-form-item label="备注：" prop="remark" class="rowBox">
                    <el-input
                            maxlength="200"
                            type="textarea"
                            :rows="2"
                            placeholder="请输入备注信息。（限200字）"
                            v-model="inputForm.remark">
                    </el-input>
                </el-form-item>
                <el-form-item label="附件：" prop="archivesBorrowFileDTOList" class="rowBox">
                    <resume-upload ref="resume" dir="archives" filtButtonName="上传附件" :filtButton="true" :allowFilesShow="false" :uploadNum="1" :allowFiles="fileFormat"
                                   @upFileDate="getFileData" :upFileSize="fileSize" v-if="visible"
                                   v-show="inputForm.archivesBorrowFileDTOList.length < 1 && method != 'view'"></resume-upload>
                    <div class="tips" v-if="inputForm.archivesBorrowFileDTOList.length < 1 && method != 'view'">
                        支持上传文档（PDF/DOC/TXT/XLSX）、图片（JPG/PNG）。单个文档不超过50M。
                    </div>
                    <el-table :data="inputForm.archivesBorrowFileDTOList" size="small" class="table m_t1" v-if="inputForm.archivesBorrowFileDTOList.length">
                        <el-table-column prop="fileName" label="附件名称" show-overflow-tooltip>
                            <template slot-scope="scope">
                                <div>{{scope.row.fileName}}</div>
                            </template>
                        </el-table-column>
                        <el-table-column fixed="right" width="180" label="操作">
                            <template slot-scope="scope">
                                <el-button type="text" size="small" @click="preview(scope.row)" :disabled="false">预览</el-button>
                                <el-button type="text" size="small" v-if="method != 'view'" @click="delFile(scope.row, scope.$index)">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-form-item>
                <el-descriptions title="借阅档案信息"></el-descriptions>
                <el-form-item label="档案：" prop="list" class="rowBox">
                    <el-button size="small" type="primary" @click="selRecord()" v-if="method != 'view'">请选择</el-button>
                    <el-table :data="inputForm.list" size="small" max-height="300" class="table m_t1" v-if="inputForm.list.length">
                        <el-table-column prop="name" label="借阅卷宗" show-overflow-tooltip>
                            <template slot-scope="scope">
                                <div>{{ scope.row.name}}</div>
                            </template>
                        </el-table-column>
                        <el-table-column fixed="right" width="180" label="操作">
                            <template slot-scope="scope">
                                <el-button type="text" size="small" @click="view(scope.row, 'view')" :disabled="false">
                                    详情
                                </el-button>
                                <el-button type="text" size="small" v-if="method != 'view'"
                                           @click="del(scope.$index)">删除
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-form-item>
            </el-form>
        </div>
        <div class="text_center p_t2">
            <el-button size="small" @click="visible = false">{{method == 'view' ? '关闭' : '取消'}}</el-button>
            <el-button size="small" type="primary" v-if="method != 'view'" @click="doSubmit('0')"
                       v-noMoreClick>保存草稿</el-button>
            <el-button size="small" type="primary" v-if="method != 'view'" @click="doSubmit('1')"
                       v-noMoreClick>提交</el-button>
        </div>
        <SelectRecordList ref="selectRecordList" @getDataList="getRecordList"></SelectRecordList>
        <RecordDetails ref="recordDetails"></RecordDetails>
        <Preview ref="preview"></Preview>
    </el-dialog>
</template>

<script>
    import SelectRecordList from '../../components/selectRecordList'
    import RecordDetails from '../../components/recordDetails'
    import resumeUpload from "@/components/Upload/resumeUpload.vue";
    import Preview from "../../../collect/preview";

    export default {
        components: {SelectRecordList, RecordDetails, resumeUpload, Preview},
        data() {
            var validateBorrowTime = (rule, value, callback) => {
                let {deadline} = this.inputForm
                if (new Date(value) < new Date().setHours(0,0,0,0)) {
                    callback(new Error('借阅时间必须大于或等于当前时间'));
                    return
                }
                if (deadline && new Date(value) > new Date(deadline)) {
                    callback(new Error('借阅时间必须小于或等于期限'));
                    return;
                }
                if (new Date(deadline) > new Date().setHours(0,0,0,0) && new Date(deadline) >= new Date(value)) {
                    this.$refs['inputForm'].clearValidate(['deadline']);
                }
                callback();
            };
            var validateDeadline = (rule, value, callback) => {
                let {borrowTime} = this.inputForm
                if (new Date(value) < new Date().setHours(0,0,0,0)) {
                    callback(new Error('期限必须大于或等于当前时间'));
                    return
                }
                if (borrowTime && new Date(value) < new Date(borrowTime)) {
                    callback(new Error('期限必须大于或等于借阅时间'));
                    return;
                }
                if (new Date(borrowTime) > new Date().setHours(0,0,0,0) && new Date(borrowTime) <= new Date(value)) {
                    this.$refs['inputForm'].clearValidate(['borrowTime']);
                }
                callback();
            };
            return {
                title: '',
                visible: false,
                method: '',
                loading: false,
                inputForm: {
                    id: '',
                    borrowCodeNumber: '',
                    borrowUserIdName: '',
                    telPhone: '',
                    unit: '',
                    unitId: '',
                    borrowTime: '',
                    deadline: '',
                    borrowPurpose: '',
                    lendUserName: '',
                    lendTime: '',
                    returnState: '',
                    returnVerificationPersonName: '',
                    returnTime: '',
                    auditState: '',
                    auditUserIdName: '',
                    auditTime: '',
                    feedback: '',
                    remark: '',
                    list: [],
                    archivesBorrowFileDTOList: [],
                },
                fileSize: 50,
                fileFormat: ['PDF', 'DOCX', 'XLSX', 'TXT', 'JPG', 'PNG'],
                // 期限大于等于借阅时间
                dataRule: {
                    borrowUserIdName: [
                        {required: true, message: '借阅人不能为空', trigger: 'blur'},
                    ],
                    telPhone: [
                        {required: true, message: '手机不能为空', trigger: 'blur'},
                        {validator: this.validator.isMobile, trigger: 'blur'}
                    ],
                    borrowTime: [
                        {required: true, message: '借阅时间不能为空', trigger: 'change'},
                        {validator: validateBorrowTime, trigger: 'blur'}
                    ],
                    deadline: [
                        {required: true, message: '期限不能为空', trigger: 'change'},
                        {validator: validateDeadline, trigger: 'blur'}
                    ],
                    borrowPurpose: [
                        {required: true, message: '借阅目的不能为空', trigger: 'change'}
                    ],
                    list: [
                        {required: true, message: '档案不能为空', trigger: 'change'}
                    ]
                }
            }
        },
        methods: {
            init(row, method) {
                this.loading = false
                this.inputForm.returnState = ''
                if (row) {
                    this.inputForm.id = row.id
                } else {
                    this.inputForm.id = ''
                }
                this.method = method
                if (method === 'add') {
                    this.title = '新增借阅申请'
                } else if (method === 'edit') {
                    this.title = '修改借阅申请'
                } else if (method === 'view') {
                    this.title = '查看借阅申请'
                }
                this.visible = true
                this.$nextTick(() => {
                    this.$refs['resume'].setOldFile([])
                    this.$refs['inputForm'].resetFields()
                    if (method !== 'add') {
                        this.getInfo()
                    } else {
                        this.inputForm.borrowUserIdName = this.$store.state.user.userInfo.name
                        this.inputForm.telPhone = this.$store.state.user.userInfo.mobilePhone
                        this.inputForm.unit = this.$store.state.user.userInfo.libraryName
                        this.inputForm.unitId = this.$store.state.user.userInfo.libraryId
                    }
                })
            },
            // 获取详情
            getInfo() {
                this.loading = true
                this.$axios(this.api.record.entityDetail + '/' + this.inputForm.id).then((res) => {
                    if (res.status) {
                        this.inputForm = this.recover(this.inputForm, res.data)
                        this.$refs['resume'].setOldFile(this.inputForm.archivesBorrowFileDTOList)
                    }
                    this.loading = false
                })
            },
            // 获取上传的文件
            getFileData(data) {
                this.inputForm.archivesBorrowFileDTOList = []
                data.map(item => {
                    console.log(item);
                    this.inputForm.archivesBorrowFileDTOList.push({
                        absolutePath: item.upDate.filePath,
                        accessPath: item.upDate.netUrl,
                        fileFormat: item.upDate.fileFormat,
                        fileName: item.upDate.fileName,
                        fileSize: item.upDate.fileSize,
                        id: item.upDate.id,
                        fileID: item.id,
                    })
                })
            },
            // 预览
            preview(item) {
                let val = JSON.parse(JSON.stringify(item))
                this.loading = true
                if(this.method != 'add') {
                    val.accessPath = val.watermarkUrl ? val.watermarkUrl : val.accessPath
                }
                this.$nextTick(() => {
                    this.$refs.preview.init(val)
                    this.loading = false
                })

            },
            // 删除附件
            delFile(row, index) {
                this.$confirm(`确定删除所选项吗?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.inputForm.archivesBorrowFileDTOList.splice(index, 1);
                    this.$refs["resume"].deleteFile(row.fileID ? row.fileID : row.id)
                })
            },
            // 选择档案
            selRecord() {
                this.$refs.selectRecordList.init(this.inputForm.list, 2)
            },
            // 获取选择的档案数据
            getRecordList(data) {
                data.map(item => {
                    this.inputForm.list.push({
                        archivesBasicDataId: item.archivesBasicDataId,
                        archivesDataId: item.id,
                        name: item.archivesDataName
                    })
                })
                if (this.inputForm.list.length) {
                    this.$refs['inputForm'].clearValidate(['list']);
                }

            },
            // 详情
            view(row, method) {
                if(row.archivesDelFag == 1) {
                    this.$message.error('该档案已删除！')
                    return
                }
                this.$refs.recordDetails.init(row, method, row.archivesBasicDataId, 'archivesDataId')
            },
            // 删除
            del(index) {
                this.$confirm(`确定删除所选项吗?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.inputForm.list.splice(index, 1);
                    this.$message.success('删除成功')
                })
            },
            // 提交
            doSubmit(type) {
                this.$refs['inputForm'].validate((valid) => {
                    if (valid) {
                        this.loading = true
                        let p = JSON.parse(JSON.stringify(this.inputForm))
                        p.auditState = type
                        let ids = []
                        this.inputForm.list.map(item => {
                            ids.push(item.archivesDataId)
                        })
                        p.list = ids
                        p.borrowTime = p.borrowTime + ' 00:00:00'
                        p.deadline = p.deadline + ' 00:00:00'
                        let api = this.api.record.addEntity,
                            methodType = 'post',
                            param = {
                                interiorBorrowDTO: p,
                                type: 2
                            }
                        if (this.method == 'edit') {
                            delete p.list
                            api = this.api.record.updateEntityBorrow
                            methodType = 'put'
                            param = {
                                archivesBorrowDTO: p,
                                list: ids,
                                menuName: this.$route.meta.title || '',
                                type: 2
                            }
                        }
                        this.$axios(api, param, methodType).then(data => {
                            if (data && data.status) {
                                this.$message.success('操作成功')
                                this.visible = false
                                this.$emit('refreshDataList')
                            } else {
                                this.$message.error(data.msg)
                            }
                            this.loading = false
                        })
                    }
                })
            },
        },
    }
</script>

<style scoped>

</style>
